import { NextPage } from 'next';

import { Seo } from '~/components/Seo';
import { Home } from '~/routes/home';

const HomePage: NextPage = () => {
	return (
		<>
			<Seo title="Home" />
			<Home />
		</>
	);
};

export default HomePage;
