import { ReceiptLongOutlined, VideocamOffOutlined } from '@mui/icons-material';
import { Alert, Badge, Box, Chip, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';

import { useGetCurrentDeviceCache } from '~/queries/useGetCurrentDevice';
import { ActiveOrdersModal } from '../menu/ActiveOrdersModal';

export function Home() {
	const [cameraError, setCameraError] = useState<any>();
	const [errorMessage, setErrorMessage] = useState('');
	const [qrContent, setQrContent] = useState('');
	const [showActiveOrders, setShowActiveOrders] = useState(false);
	const { data: device } = useGetCurrentDeviceCache();

	const toggleActiveOrders = () => {
		setShowActiveOrders((bool) => !bool);
	};

	return (
		<Container
			maxWidth={false}
			sx={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
				position: 'relative',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					zIndex: 20,
					top: { xs: 16, sm: 24 },
					right: { xs: 16, sm: 24 },
					color: grey[500],
				}}
				onClick={toggleActiveOrders}
			>
				{device && device._active_orders.length ? (
					<Badge badgeContent={device._active_orders.length} color="primary">
						<ReceiptLongOutlined />
					</Badge>
				) : (
					<Box>
						<ReceiptLongOutlined />
					</Box>
				)}
			</Box>

			<ActiveOrdersModal open={showActiveOrders} onClose={toggleActiveOrders} />

			{cameraError ? (
				<Stack sx={{ gap: 2, justifyContent: 'center', alignItems: 'center' }}>
					<VideocamOffOutlined fontSize="large" />
					Couldnt start camera: &nbsp;
					{typeof cameraError === 'string' ? cameraError : cameraError?.message || 'Unknown error'}
				</Stack>
			) : (
				<>
					<Box textAlign="center">
						<Typography variant="body1" fontWeight="bold">
							Scan QR
						</Typography>
						<Typography variant="body2">Point this at Served QR</Typography>
					</Box>

					<Box sx={{ width: 350 }}>
						<QrScanner
							constraints={{
								facingMode: 'environment',
								width: { min: 640, ideal: 720, max: 1920 },
								height: { min: 640, ideal: 720, max: 1080 },
							}}
							scanDelay={1000}
							onResult={(result) => {
								if (!result) return;

								const qrTextContent = result.getText();
								const isServedQr = qrTextContent.startsWith(process.env.SHORTEN_URL as string);
								if (!isServedQr) {
									setErrorMessage('Not a valid Served QR. Try again.');
									return;
								}

								setErrorMessage('');
								setQrContent(qrTextContent);

								window.location.replace(qrTextContent);
							}}
							onError={setCameraError}
						/>
					</Box>
				</>
			)}

			{!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
			{!!qrContent && (
				<Alert icon={<CircularProgress size="24px" />} severity="warning">
					Taking you to the menu... &nbsp;
				</Alert>
			)}

			<Stack
				direction="row"
				sx={{
					position: 'fixed',
					bottom: 0,
					gap: 2,
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<img src="/images/favicon.png" width="50px" />

				<Chip
					label={[process.env.VERSION, process.env.ENV].filter(Boolean).join('-')}
					variant="filled"
					color="primary"
				/>
			</Stack>
		</Container>
	);
}
